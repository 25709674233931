<template>
    <!--政策法规详情-->
    <div class="base-container">
        <div class="main-wrapper">
            <el-container style="height: 100%; border: 1px solid #eee; background:#fff">
                <div class="back" @click="back">返回上级</div>
                <!-- <el-header>
                    <div class="header">
                        <div class="search_box">
                            <p class="header_title">政策法规查询</p>
                            <el-input class="search-content" prefix-icon="el-icon-search" size="small" v-model="keyword" placeholder="输入关键字，搜索法规" ></el-input>
                            <el-button class="search_btn" type="primary" size="small" @click="searchClick()">搜索</el-button>
                        </div>
                        <div class="search_box">
                            <p class="search_title">热门搜索:</p>
                            <p class="search_tag">土地增值税</p>
                            <p class="search_tag">清算单位</p>
                            <p class="search_tag">应该后土地增值税</p>
                            <p class="search_tag">更多</p>
                        </div>
                        
                    </div>
                </el-header> -->
                <el-container>
                    <p class="detail_title">{{detailData.title}}</p>
                    <div class="detail_tag">
                        <p>发文日期：{{detailData.publishDate}}</p>
                        <p>法规编号：{{detailData.lawNo}}</p>
                    </div>
                    <div class="text" v-html="contant">
                    </div>
                </el-container>
            </el-container>
        </div>
    </div>
</template>

<script>

import {getStore,setStore,getLocalStore} from "@/util/store";
import {mapMutations} from "vuex";

export default {
    name: "index",
   

    data() {
        return {
          keyword:'',
          isSearch:false,
          abstractId:'',
          lawId:'',
          contant:'',
          detailData:{}
        }
    },
    created() {
        if(this.$route.query.abstractId&&this.$route.query.lawId){
            this.abstractId = this.$route.query.abstractId
            this.lawId = this.$route.query.lawId
            this.getDetail()
        }
      
        
    },
    mounted() {
    },
    methods: {
        ...mapMutations(["reducePath", "addPath", "addMenuList", "reduceMenuList"]),
        back() {
            this.getReport(2,'back','backBtn');
            this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
            this.reducePath();//删除导航最后一个路由
            this.$router.push({path: "/policiesRegulation"});
        },
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'detail',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        getDetail(){
            this.axios.request({
                loading: true,
                method: "get", 
                url: '/dms/law/getLawDetail',
                 
                params:{
                    abstractId:this.abstractId,
                    lawId:this.lawId,
                 
                }
            }).then((res) => {
                this.detailData = res.data.data;
                this.contant = res.data.data.detail;
            
            })
        }
     
        
       
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
    width: 0;
}
.el-header{
  height: 98px !important;  
  padding: 0;
  border-bottom: 1px solid #EBEBEB;
}
/deep/.el-container{
    height: auto !important;
    min-height: 600px;
    display: block !important;
}
.header{
    width: 610px;
    margin: 0 auto;
    height: 98px;
    
}
.search_box{
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.search_title{
    font-size: 14px;
    color: #323233;
}
.search_tag{
    font-size: 14px;
    font-weight: 400;
    color: #185BCE;
    margin-right: 16px;
    cursor: pointer;
}
.header_title{
    width: 141px !important;
    height: 22px;
    font-size: 16px;
    color: #185BCE;
    font-weight: 600;
    margin-right: 10px;
}
.search_btn{
    margin-left: 21px;
    width: 84px;
}
.main-wrapper{
    height: 100%;
}
.allMenu{
    font-weight: 600;
    color: #333333;
}
/deep/.el-submenu__title{
    font-size: 14px !important;
    font-weight: 600;
    color: #333333;
    -webkit-line-clamp: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
}
/deep/.el-submenu__title i {
    color: #000000;
    font-weight: 600;
}
.el-menu-item.is-active{
    background: #EDF4FF !important;
    color: #185BCE !important;
}
.chart_box{
    width: 100%;
    background: #ffffff;
    padding: 16px;
    box-sizing: border-box;
}
.el-main{
    height: 684px;
    padding: 0 !important;
}
.text{
    width: 959px;
    margin: 0 auto;
    padding-bottom: 50px;
    line-height: 20px;
}

.detail_title{
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height: 22px;
    margin: 16px 0;
}
.detail_tag{
    overflow: hidden;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
     >p{
        margin-right: 16px;
    }
}
.back{
    position: absolute;
    top: 20px;
    right: 20px;
    color: #185BCE;
    cursor: pointer;
}
</style>